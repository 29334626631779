import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';

import theme from './theme';
import Auth from './Auth';
import {Message} from './component';

export default class App extends React.Component{
    render() {
        return (
            <ThemeProvider theme={theme}>
                <BrowserRouter basename={process.env.REACT_APP_PREFIX}>
                    <Message/>
                    <Auth />
                </BrowserRouter>
            </ThemeProvider>
        )
    }
}