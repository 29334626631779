import React, { useState } from "react";
import PropTypes from "prop-types";
import { AppBar, Toolbar, IconButton, Avatar, Hidden, Link, Dialog, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MailIcon from '@material-ui/icons/Mail';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import pathConfig from '../../../../config';
import Axios from "axios";


const useStyles = makeStyles(theme=>({
    appBar:{
        zIndex: theme.zIndex.drawer + 1
    },
    flexGrow: {
        flexGrow: 1
    }
}));

const Topbar = (props)=>{
    const {onSidebarOpen, user} = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleClickExit = ()=>{
        Axios.delete(pathConfig.api.token)
        .then(response=>{
            if(response.data.code === 0){
                window.localStorage.removeItem('token');
                window.localStorage.removeItem('adminId');
                window.location.href = pathConfig.login;
            }
        });
    };

    return (
        <AppBar
        className={classes.appBar}
        color="inherit">
            <Toolbar>
                <Link href={process.env.REACT_APP_DOMAIN}>
                    <img 
                    alt="Kotomiko"
                    src={`${process.env.REACT_APP_DOMAIN}/images/logos/logo--white.svg`}
                    />
                </Link>
                <div className={classes.flexGrow}/>
                <IconButton>
                    <Avatar className={classes.avatar} alt={user.name} src={user.avatar}/>
                </IconButton>
                <IconButton>
                    <NotificationsIcon />
                </IconButton>
                <IconButton>
                    <MailIcon />
                </IconButton>
                <IconButton onClick={()=>setOpen(true)}>
                    <ExitToAppIcon/>
                </IconButton>
                <Hidden lgUp>
                    <IconButton
                        onClick={onSidebarOpen}>
                        <MenuIcon />
                    </IconButton>
                </Hidden>
            </Toolbar>
            <Dialog
                open={open}
                onClose={()=>setOpen(false)}
            >
                <DialogContent>
                    <DialogContentText id="exit-dialog">确认要退出管理后台页面吗</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setOpen(false)} color="inherit">取消</Button>
                    <Button onClick={handleClickExit} color="secondary" autoFocus>退出</Button>
                </DialogActions>
            </Dialog>
        </AppBar>
    )
}

Topbar.propTypes = {
    onSidebarOpen : PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
}

export default Topbar;