import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTheme, makeStyles } from "@material-ui/styles";
import { useMediaQuery, CssBaseline } from "@material-ui/core";

import { 
    Siderbar,
    Topbar
 } from "./components";

const useStyle = makeStyles(theme => ({
    root: {
        display: 'flex'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3)
    },
    toolBar: theme.mixins.toolbar
}));

const Main = props=>{
    const {children} = props;
    const classes = useStyle();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches : true
    });

    const [openSidebar, setOpenSidebar] = useState(false);

    const handleCLoseSiderbar = (event)=>{
        if(isDesktop){
            return;
        }
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpenSidebar(false);
    };
  
    return (
        <div className={classes.root}>
            <CssBaseline />
            <Topbar 
                onSidebarOpen={()=>setOpenSidebar(true)}
                user={props.user}
            />
            <Siderbar 
                onClose={handleCLoseSiderbar}
                open={isDesktop ? true : openSidebar}
                userId={props.user.id}
                variant={isDesktop ? 'persistent' : 'temporary'}
            />
            <main className={classes.content}>
                <div className={classes.toolBar}/>
                {children}
            </main>
        </div>
    )
}

Main.propTypes = {
    children : PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
}

export default Main;