import React from "react";
import PropTypes from "prop-types";
import { Drawer, List } from "@material-ui/core";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import HistoryIcon from '@material-ui/icons/History';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FolderIcon from '@material-ui/icons/Folder';
import DescriptionIcon from '@material-ui/icons/Description';
import { makeStyles } from "@material-ui/styles";
import SiderItem from "./SiderItem";

const menus = (userId)=>{
    return [
        {
            primary : '管理员',
            icon : <AccountCircleIcon />,
            childrens : [
                {
                    primary : '基本信息',
                    icon : <PermIdentityIcon />,
                    url: `/admin/${userId}`
                },
                {
                    primary : '修改密码',
                    icon : <VpnKeyIcon />,
                    url: `/admin/${userId}/password`
                },
                {
                    primary : '历史记录',
                    icon : <HistoryIcon />,
                    url: `/admin/${userId}/history`
                }
            ]
        },
        {
            primary : '信息展示',
            icon : <VisibilityIcon />,
            url: `/lords/${userId}`
        },
        {
            primary : '博文',
            icon : <FolderIcon />,
            childrens : [
                {
                    primary : '文章',
                    icon : <DescriptionIcon />,
                    url: '/articles'
                }
            ]
        }
    ];
};

const useStyles = makeStyles(theme=>({
    drawer: {
        width: 240,
        flexShrink: 0,
    },
    drawerPaper: {
        width: 240,
    },
    logo: {
        paddingTop: 12,
        paddingBottom: 12
    },
    toolBar: theme.mixins.toolbar
}));

const Siderbar = props=>{
    const { variant, open, onClose, userId } = props;
    const classes = useStyles();

    return (
        <Drawer
        className = {classes.drawer}
        variant = {variant}
        open = {open}
        onClose={onClose}
        classes = {{paper : classes.drawerPaper}}
        >
            <div className={classes.toolBar}/>
            <List 
            component="nav">
                {
                    menus(userId).map((menu)=>{
                        return <SiderItem key={menu.primary} item={menu}/>
                    })
                }
                
            </List>
        </Drawer>
    );
};

Siderbar.propTypes = {
    variant : PropTypes.string,
    open : PropTypes.bool,
    userId: PropTypes.string
}

export default Siderbar;