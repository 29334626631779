import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
    palette : {
        type : 'dark',
        success: {
            light: '#81c784',
            main: '#4caf50',
            dark: '#388e3c',
            contrastText: 'rgba(0,0,0,0.87)'
        },
        info: {
            light: '#64b5f6',
            main: '#2196f3',
            dark: '#1976d2',
            contrastText: '#fff'
        },
        warning: {
            light: '#ffb74d',
            main: '#ff9800',
            dark: '#f57c00',
            contrastText: 'rgba(0,0,0,0.87)'
        }
    }
});

export default theme;