import React from 'react';
import {Link} from '@material-ui/core'
import { useLocation } from 'react-router-dom';
import pathConfig from '../../config';

const NotFound = ()=>{
    const location = useLocation();
    return (
    <div>Page Not Found. location : {location.pathname}. 回到<Link href={`${pathConfig.prefix}/`}>首页</Link></div>
    )
};

export default NotFound;