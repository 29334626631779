import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Snackbar, Slide } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import PathConfig from '../config';

const messageArray = [];

const Message = ()=>{
    const [message, setMessage] = useState(false);

    const handleSnackbarClose = ()=>{
        const first = messageArray[1];
        if(typeof first === 'undefined'){
            messageArray.shift();
            setMessage(false);
            return;
        }
        first.open = true;
        messageArray.shift();

        setMessage({...message, open: false});
        setMessage(messageArray[0]);
    };

    const isMessageWorking = ()=>{
        const first = messageArray[0];
        if(first && first.open === null){
            first.open = true;
            setMessage(first);
        }
    };

    useEffect(()=>{
        Axios.interceptors.request.use(config=>{
            if(config.method === 'get'){
                return config;
            }
            messageArray.push({
                key: `${config.method}|${config.url}`,
                message: '请求发送中，请耐心等待!!!',
                requesting: true,
                fail: false,
                open: null
            });
            isMessageWorking();
            return config;
        }, error=>{
            console.log(error);
            return Promise.reject(error);
        });

        Axios.interceptors.response.use(response=>{
            const key = `${response.config.method}|${response.config.url.replace(response.config.baseURL, '/')}`;
            const responseText = PathConfig.responseCode[response.data.code];
            if(response.config.method === 'get' && (response.status !== 200 || response.data.code !== 0)){
                messageArray.push({
                    key: key,
                    message: `服务器响应失败。响应信息: ${responseText}`,
                    requesting: false,
                    fail: true,
                    open: null
                });
                isMessageWorking();
                return response;
            }
            for(let [index, element] of messageArray.entries()){
                if(element.key === key){
                    element.fail = response.status !== 200 || response.data.code !== 0;
                    element.message = `服务器响应${element.fail ? '失败' : '成功'}。响应信息: ${responseText}`;
                    element.requesting = false;
                    if(index === 0){
                        setMessage({...element});
                    }
                }
            }
            return response;
        }, error=>{
            messageArray.push({
                key: error,
                message: `服务器响应失败。响应信息: ${error}`,
                requesting: false,
                fail: true,
                open: null
            });
            isMessageWorking();
            return Promise.reject(error);
        });
    }, []);

    return (
        message && 
        <Snackbar 
        key={message.key} 
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} 
        autoHideDuration={3000} 
        open={message.open}
        TransitionComponent={(props)=>(<Slide {...props} direction="up" />)}
        onClose={handleSnackbarClose}>
            <Alert 
            severity={message.requesting ? "info" : message.fail ? "error" : "success"} 
            variant="filled">
                {message.message}
            </Alert>
        </Snackbar>
    )
};

export default Message;