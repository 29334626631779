import React, { useState } from 'react';
import MdEditor from 'react-markdown-editor-lite'
import MarkdownIt from 'markdown-it'
import { makeStyles, Box, TextField, Container, Button } from '@material-ui/core';
import SendSharpIcon from '@material-ui/icons/SendSharp';
import RotateLeftSharpIcon from '@material-ui/icons/RotateLeftSharp';
import PathConfig from '../../../config';
import Axios from 'axios';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme=>({
    forms: {
        margin: "0 auto",
        marginTop: theme.spacing(8),
        width: "100%"
    },
    mdEditorBox: {
        height: 500
    },
    input: {
        marginTop: theme.spacing(4)
    },
    button: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    }
}));


const Add = ()=>{
    const classes = useStyles();
    const location = useLocation();
    const mdParser = new MarkdownIt();
    const [values, setValues] = useState({
        title: '',
        content: ''
    });

    const handleMdChange = ({text})=>{
        setValues({...values, content: text});
    };

    const handleTitleChange = (event)=>{
        setValues({...values, title: event.target.value});
    };

    const handleReset = ()=>{
        setValues({ ...values, title: '', content: ''});
    };

    const handleImageUpload= (file, callback)=>{
        const reader = new FileReader();
        reader.onload = () => {
            Axios.post(PathConfig.api.avatar, {
                "resources": reader.result.replace(/^data:image\/\w+;base64,/, "")
            }).then((response)=>{
                if(response.data.code === 0){
                    callback(response.data.result);
                }
            });
        };
        reader.readAsDataURL(file);
    };

    const handleCommit = ()=>{
        Axios.post(PathConfig.api.articles, values)
        .then(response=>{
            if(response.data.code === 0){
                setTimeout(() => {
                    window.location.href = location.pathname;
                }, 5000);
            }
        });
    };

    return (
        <Container >
            <Box component="form" className={classes.forms}>
                <TextField
                    id="article-title"
                    placeholder="文章标题"
                    helperText="文章的标题"
                    fullWidth
                    value={values.title}
                    onChange={handleTitleChange}
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    />
                    <div className={classes.mdEditorBox}>
                        <MdEditor
                        style={
                            {'zIndex': '9999'}
                        }
                        onChange={handleMdChange}
                        onImageUpload={handleImageUpload}
                        value={values.content}
                        renderHTML={(text)=>mdParser.render(text)}
                        />
                    </div>
                    <Container className={`${classes.input} text-center`}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={handleCommit}
                            endIcon={<SendSharpIcon/>}
                        >
                            提交
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={handleReset}
                            endIcon={<RotateLeftSharpIcon/>}
                        >
                            重置
                        </Button>
                </Container>
            </Box>
        </Container>
    )
};

export default Add;