import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Typography, makeStyles } from '@material-ui/core';
import hljs from 'highlight.js';
import moment from 'moment';
import pathConfig from '../../../config';
import MarkdownIt from 'markdown-it';
import 'highlight.js/styles/github.css'
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme=>({
    root: {
        [theme.breakpoints.up('md')]: {
            width: 800,
        },
        margin: '0 auto'
    },
    article: {
        backgroundColor: '#fff',
        padding: theme.spacing(4)
    }
}));


const View = ()=>{
    const classes = useStyles();
    const {id} = useParams();
    const [values, setValues] = useState({
        content: ''
    });
    const markDown = new MarkdownIt({
        linkify: true,
        typographer: true,
        highlight: (str, lang)=>{
            if(lang && hljs.getLanguage(lang)){
                return hljs.highlight(lang, str).value;
            }
            return '';
        }
    });

    useEffect(()=>{
        axios.get(pathConfig.api.article(id))
        .then(response=>{
            if(response.data.code === 0){
                setValues(response.data.result);
            }
        });
    }, [id]);
    return (
        <section className={classes.root}>
            <Typography align="center" component="h1">{values.title}</Typography>
            <Typography align="right">创建于: {moment(values.createDatetime).utcOffset(moment().utcOffset() * 2 - 480).format("LLLL")}</Typography>
            <article className={`${classes.article} custom-html-style`}  dangerouslySetInnerHTML={{__html: markDown.render(values.content)}}></article>
        </section>
    )
};

export default View;