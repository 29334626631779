import React, { useState } from "react";
import PropTypes from "prop-types";
import {NavLink} from 'react-router-dom';
import { List, ListItem, ListItemText, ListItemIcon, Collapse } from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme)=>({
    link:{
        textDecoration:'none',
        color:'#fff'
    },
    active:{
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
    }
}))

const create = (item, classes)=>{
    return (
        <ListItem key={item.primary} button>
            {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
            <NavLink activeClassName={classes.active} className={classes.link} to={item.url}>{item.primary}</NavLink>
        </ListItem>
    )
}

const SiderItem = (props)=>{
    const {item} = props;
    const [isOpen , setOpen] = useState(true);
    const classes = useStyles();

    if(typeof item.childrens === 'undefined'){
        return create(item, classes);
    }
    return (
        <div>
            <ListItem button onClick={()=>setOpen(!isOpen)}>
                {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                <ListItemText primary={item.primary} />
                {isOpen ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
            </ListItem>

            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {
                        item.childrens.map((child)=>{
                            return create(child, classes)
                        })
                    }
                </List>
            </Collapse>
        </div>
    );
};

SiderItem.propTypes = {
    item : PropTypes.object.isRequired
}

export default SiderItem;