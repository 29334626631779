import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { 
    makeStyles, 
    Container, 
    Box, 
    TableCell, 
    Table, 
    TableHead, 
    TableRow, 
    TableBody, 
    TablePagination, 
    TableFooter, 
    FormControl,
    InputLabel,
    Select,
    TextField
} from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import axios from "axios";
import moment from "moment";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import pathConfig from "../../config";

const typeConvert = {
    UPDATE_PASSWORD: "修改密码",
    UPDATE_ADMIN: "修改管理员",
    LOGIN: "登录",
    UPDATE_ARTICLE: '修改文章',
    INSERT_ARTICLE: '新增文章',
    UPDATE_LORD: '修改博主信息'
}

const useStyles = makeStyles(theme=>({
    root: {
        margin: theme.spacing(8)
    },
    forms: {
        margin: "0 auto",
        marginTop: theme.spacing(8),
    },
    formControl: {
        minWidth: 200,
        marginRight: theme.spacing(2)
    },
    input: {
        marginBottom: theme.spacing(4)
    },
    button: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    }
}));

const AdminHistory = (props)=>{
    const {user} = props;
    const classes = useStyles();

    const [localValues, setLocalValues] = useState({
        begin: moment().subtract(3, "d").hours(0).minutes(0).seconds(0).milliseconds(0),
        end: moment(),
        ip: ""
    });
    const [result ,setResult] = useState({
        result: [],
        page: 1,
        limit: 20,
        records: 0,
    });
    const [search, setSearch] = useState({
        type: "",
        ip: localValues.ip,
        begin: localValues.begin,
        end: localValues.end,
        sorts:{
            operation_datetime: "-"
        }
    });

    const handleChangeIp = (event)=>{
        const array = event.target.value.split(".");
        const value = `${event.target.value}${array.length < 4 && array[array.length - 1].length === 3 ? "." : ""}`;
        setLocalValues({...localValues, ip: value})
        if(array.length === 4 && array[array.length - 1].length !== 0){
            setSearch({...search, ip: value})
        }
    };

    const handleChangeSearch = (prop)=>(event)=>{
        setSearch({...search, [prop]: event.target.value})
    };

    const handleCloseTime = (prop)=>()=>{
        setSearch({...search, [prop]: localValues[prop]})
    };

    const handleChangeTime = (prop)=>(moment, format)=>{
        setLocalValues({...localValues, [prop]: moment})
    };

    const handleChangePage = (event, newPage)=>{
        setResult({...result, page: newPage + 1})
    };

    const handleChangeLimit = (event)=>{
        setResult({...result, limit: event.target.value, page: 1})
    };

    const handleChangeSorts = (sort)=> ()=>{
        setSearch({...search, sorts: {...search.sorts, [sort]: search.sorts[sort] === "-" ? "+" : "-"}})
    };

    useEffect(()=>{
        axios.get(pathConfig.api.adminHistory(user.id), {
            params: {
                type: search.type,
                ip: search.ip,
                begin: moment(search.begin).utcOffset(0).format("YYYY-MM-DDTHH:mm:ss"),
                end: moment(search.end).utcOffset(0).format("YYYY-MM-DDTHH:mm:ss"),
                page: result.page,
                limit: result.limit,
                sorts: Object.keys(search.sorts).map(value=>`${search.sorts[value]}${value}`).join()
            }
        })
        .then(response=>{
            if(response.data.code === 0){
                setResult({result: response.data.result, ...response.data.page})
            }
        });
    }, [result.page, result.limit, search.sorts, search.type, search.begin, search.end, search.ip, user.id]);

    return (
        <Container className={classes.root}>
            <Box component="form" className={classes.forms}>
                <Box>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="operator-type">操作类型</InputLabel>
                        <Select
                        native
                        value={search.type}
                        onChange={handleChangeSearch("type")}
                        inputProps={{
                            name: 'operator-type',
                            id: 'operator-type',
                          }}
                        >
                        <option key="" value=""></option>
                        {Object.keys(typeConvert).map(key=>(<option key={key} value={key}>{typeConvert[key]}</option>))}
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField 
                        id="operator-ip" 
                        label="IP地址" 
                        value={localValues.ip}
                        onChange={handleChangeIp}
                        inputProps={{maxLength: "15"}} 
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDateTimePicker
                                variant="inline"
                                value={localValues.begin}
                                onChange={handleChangeTime("begin")}
                                onClose={handleCloseTime("begin")}
                                format="YYYY-MM-DD HH:mm:ss"
                                id="begin-datetime"
                                label="开始时间"
                                />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDateTimePicker
                                variant="inline"
                                value={localValues.end}
                                onChange={handleChangeTime("end")}
                                onClose={handleCloseTime("end")}
                                format="YYYY-MM-DD HH:mm:ss"
                                id="end-datetime"
                                label="结束时间"
                                />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                </Box>
                <TablePagination
                component="div"
                rowsPerPageOptions={[10, 20, 50 ,100]}
                labelRowsPerPage="每页最大记录"
                labelDisplayedRows={({ from, to, count }) =>{return `第 ${from} - ${to === -1 ? count : to} 行. 共 ${count} 行记录`}}
                count={result.records}
                rowsPerPage={result.limit}
                page={result.page - 1}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeLimit}
            />
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <TableCell>No.</TableCell>
                            <TableCell align="right">用户名</TableCell>
                            <TableCell align="right">操作类型</TableCell>
                            <TableCell align="right">操作前</TableCell>
                            <TableCell align="right">操作后</TableCell>
                            <TableCell align="right">操作ip</TableCell>
                            <TableCell align="right">
                                操作时间
                                {search.sorts.operation_datetime && search.sorts.operation_datetime === "-" && <ExpandMoreIcon onClick={handleChangeSorts("operation_datetime")}/>}
                                {search.sorts.operation_datetime && search.sorts.operation_datetime === "+" && <ExpandLessIcon onClick={handleChangeSorts("operation_datetime")}/>}
                            </TableCell>
                            <TableCell align="right">设置</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {result.result.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">{index + 1}</TableCell>
                                <TableCell align="right">{row.adminName}</TableCell>
                                <TableCell align="right">{typeConvert[row.type]}</TableCell>
                                <TableCell align="right">{"-"}</TableCell>
                                <TableCell align="right">{"-"}</TableCell>
                                <TableCell align="right">{row.ip}</TableCell>
                                <TableCell align="right">{moment(row.dateTime).utcOffset(moment().utcOffset() * 2).format("LLLL")}</TableCell>
                                <TableCell align="right">{"-"}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[10, 20, 50 ,100]}
                                labelRowsPerPage="每页最大记录"
                                labelDisplayedRows={({ from, to, count }) =>{return `第 ${from} - ${to === -1 ? count : to} 行. 共 ${count} 行记录`}}
                                count={result.records}
                                rowsPerPage={result.limit}
                                page={result.page - 1}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeLimit}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </Box>
        </Container>
    )
}

AdminHistory.propTypes = {
    user: PropTypes.object.isRequired
}

export default AdminHistory;