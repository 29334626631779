import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import moment from 'moment';
import MarkdownIt from 'markdown-it'
import { makeStyles, Container, ExpansionPanelDetails, ExpansionPanelSummary, Typography, ExpansionPanel, TablePagination, Box, FormControl, Tooltip, Fab, Link } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

import PathConfig from '../../config';
import { useRouteMatch } from 'react-router-dom';

const useStyles = makeStyles(theme=>({
    root: {
        margin: theme.spacing(8)
    },
    forms: {
        margin: "0 auto",
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(4),
    },
    formControl: {
        minWidth: 200,
        marginRight: theme.spacing(2)
    },
    column: {
        flexBasis: '50%',
    },
    edit: {
        textAlign: 'right',
        display: 'flex',
        flexDirection: 'row-reverse',
        '& *': {
            marginLeft: theme.spacing(2),
        }
    }
}));


const Article = ()=>{
    const classes = useStyles();
    const {url} = useRouteMatch();
    const markDown = new MarkdownIt();
    const [local, setLocal] = useState({
        begin: moment().subtract(3, "d").hours(0).minutes(0).seconds(0).milliseconds(0),
        end: moment(),
    });

    const [result, setResult] = useState({
        result: [],
        page: {
            page: 1,
            limit: 10,
            records: 0
        }
    });
    const [search, setSearch] = useState({
        begin: local.begin,
        end: local.end,
        sorts: {
            create_datetime: "-"
        }
    });

    const handleCloseTime = (prop)=>()=>{
        setSearch({...search, [prop]: local[prop]})
    };

    const handleChangeTime = (prop)=>(moment, format)=>{
        setLocal({...local, [prop]: moment})
    };

    const handleChangePage = (event, newPage)=>{
        setResult({...result, page: {...result.page, page: newPage + 1}});
    };

    const handleChangeLimit = (event)=>{
        setResult({...result, page: {...result.page, limit: event.target.value, page: 1}});
    };

    useEffect(()=>{
        Axios.get(PathConfig.api.articles, {
            params: {
                begin: moment(search.begin).utcOffset(0).format("YYYY-MM-DDTHH:mm:ss"),
                end: moment(search.end).utcOffset(0).format("YYYY-MM-DDTHH:mm:ss"),
                page: result.page.page,
                limit: result.page.limit,
                sorts: Object.keys(search.sorts).map(value=>`${search.sorts[value]}${value}`).join()
            }
        })
        .then(response=>{
            if(response.data.code === 0){
                setResult({result: response.data.result, page: response.data.page});
            }
        });
    }, [result.page.page, result.page.limit, search.begin, search.end, search.sorts]);

    return (
        <Container className={classes.root}>
            <Box component="form" className={classes.forms}>
                <FormControl className={classes.formControl}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDateTimePicker
                            variant="inline"
                            value={local.begin}
                            onChange={handleChangeTime("begin")}
                            onClose={handleCloseTime("begin")}
                            format="YYYY-MM-DD HH:mm:ss"
                            id="begin-datetime"
                            label="开始时间"
                            />
                    </MuiPickersUtilsProvider>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDateTimePicker
                            variant="inline"
                            value={local.end}
                            onChange={handleChangeTime("end")}
                            onClose={handleCloseTime("end")}
                            format="YYYY-MM-DD HH:mm:ss"
                            id="end-datetime"
                            label="结束时间"
                            />
                    </MuiPickersUtilsProvider>
                </FormControl>
                <Tooltip className={classes.icons} title="新增文章" aria-label="add">
                    <Fab color="secondary" component="a" href={`${url}?type=add`} size="medium">
                        <AddIcon/>
                    </Fab>
                </Tooltip>
            </Box>
            {
                result.result.map((value)=>(
                    <ExpansionPanel component="section" key={value.id} defaultExpanded>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="article-title"
                            id={value.id}
                        >
                            <div className={classes.column}>
                                <Typography><Link color="inherit" href={`${url}/${value.id}`}>{value.title}</Link></Typography>
                                
                            </div>
                            <div className={`${classes.column} ${classes.edit}`}>
                                <Link href={`${url}/${value.id}?type=edit`}><EditIcon color="action"/></Link>
                                <Typography>{moment(value.createDatetime).utcOffset(moment().utcOffset() * 2).format("LLLL")}</Typography>
                            </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <article dangerouslySetInnerHTML={{__html: markDown.render(value.summary)}}/>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                ))
            }
            <TablePagination
            component="div"
            rowsPerPageOptions={[10, 20, 50 ,100]}
            labelRowsPerPage="每页最大记录"
            labelDisplayedRows={({ from, to, count }) =>{return `第 ${from} - ${to === -1 ? count : to} 行. 共 ${count} 行记录`}}
            count={result.page.records}
            rowsPerPage={result.page.limit}
            page={result.page.page - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeLimit}
            />
        </Container>
    )
};

export default Article;