import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Container , TextField, Avatar, makeStyles, Box, Fab} from "@material-ui/core";
import AvatarEditor from "react-avatar-editor";
import DoneIcon from '@material-ui/icons/Done';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import axios from "axios";
import config from "../../config";

const useStyles = makeStyles(theme=>({
    root: {
        margin: theme.spacing(8)
    },
    avatar: {
        width: theme.spacing(18),
        height: theme.spacing(18),
        margin: "0 auto",
        cursor: "pointer"
    },
    forms: {
        margin: "0 auto",
        marginTop: theme.spacing(8),
        width: "50%"
    },
    icons: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    }
}));

const Admin = (props)=>{
    const classes = useStyles();
    const {user} = props;
    const [avatar, setAvatar] = useState(false);

    let editorRef;

    const avatarClick = ()=>{
        document.getElementsByName('admin-avatar-file')[0].click();
    };
    const avatarChange = (e)=>{
        setAvatar(e.currentTarget.files[0]);
    };
    const avatarCancel = (e)=>{
        document.getElementsByName('admin-avatar-file')[0].value = '';
        setAvatar(false);
    };
    const setEditorRef = (editor) => editorRef = editor;
    const avatarDone =()=>{
        let newAvatar = editorRef.getImageScaledToCanvas().toDataURL("image/jpeg", 0.6);
        // let newAvatar = editorRef.getImage().toDataURL("image/jpeg", 1.0);
        axios.post(config.api.avatar, {
            "resources": newAvatar.replace(/^data:image\/\w+;base64,/, "")
        }).then((response)=>{
            if(response.data.code === 0){
                axios.put(config.api.admin(user.id), {
                    "avatar": response.data.result,
                    "email": user.email
                }).then(response=>{
                    setAvatar(false);
                    props.changeUser({...user, ...response.data.result});
                });
            }
        });
    };
    return (
        <Container className={classes.root}>
                {avatar ? 
                    <Container className="text-center">
                        <AvatarEditor
                        ref={setEditorRef}
                        image={avatar}
                        width={250}
                        height={250}
                        border={5}
                        borderRadius={125}
                        color={[255, 255, 255, 0.6]} // RGBA
                        scale={1.2}
                        rotate={0}
                        />  
                        <Container>
                            <Fab color="primary" size="small" aria-label="done" className={classes.icons} onClick={avatarDone}>
                                <DoneIcon />
                            </Fab>
                            <Fab color="secondary" size="small" aria-label="cancel" className={classes.icons} onClick={avatarCancel}>
                                <KeyboardReturnIcon />
                            </Fab>
                        </Container>
                    </Container>
                : 
                <Avatar alt={user.name} src={user.avatar} className={classes.avatar} onClick={avatarClick}/>
                }
            <Box component="form" className={classes.forms}>
                <input type="file" className="hidden" name="admin-avatar-file" accept="image/png,image/jpeg" onChange={avatarChange}/>
                <TextField
                id="admin-name"
                label="用户名"
                style={{ margin: 8 }}
                placeholder="请输入用户名"
                helperText="管理员登录用户名"
                fullWidth
                disabled
                defaultValue={user.name}
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                    shrink: true,
                }}
                />
                <TextField
                id="admin-mail"
                label="邮箱"
                style={{ margin: 8 }}
                placeholder="请输入邮箱"
                helperText="管理员联系用邮箱"
                fullWidth
                defaultValue={user.email}
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                    shrink: true,
                }}
                />
            </Box>
        </Container>
    )
}

Admin.propTypes = {
    user: PropTypes.object.isRequired
}

export default Admin;