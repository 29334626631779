const config = {
    login : process.env.REACT_APP_LOGIN_PATH,
    api : {
        token: '/v1/resources/token',
        admin : (adminId)=>`/v1/admins/${adminId}`,
        adminPassword: (adminId)=>`${config.api.admin(adminId)}/password`,
        adminHistory: (adminId)=>`${config.api.admin(adminId)}/operation_histories`,
        lord: (adminId)=>`/v1/lords/${adminId}`,
        articles: "/v1/articles",
        article: (articleId)=>`${config.api.articles}/${articleId}`,
        avatar : "/v1/resources/file"
    },
    responseCode: {
        0: "操作成功",
        3001: "用户名格式错误",
        3002: "密码格式错误",
        3003: "Token无效",
        3004: "不支持的资源格式或地址",
        3005: "邮箱格式错误，或不支持",
        3006: "URL格式错误",
        3007: "时间格式错误",
        3008: "IP地址格式错误",
        3009: "分页格式错误",
        3010: "无效的结果集数量",
        3011: "无效的排序请求",
        3012: "无效的昵称格式",
        3013: "个人简介格式错误",
        3014: "链接格式错误",
        3015: "标题格式错误",
        3016: "正文格式不支持",
        3098: "无效的状态",
        3099: "不支持的参数",
        5001: "不存在的用户名",
        5002: "密码无效",
        5003: "Token无效",
        5004: "不支持的资源类型",
        5005: "无效的资源",
        5006: "无效的类型",
        5099: "无效的资源",
        6001: "无效的Token",
        9998: "权限不足",
        9999: "未知错误"
    }
}

export default config;