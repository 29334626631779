import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Container , makeStyles, Box, FormControl, IconButton, InputLabel, OutlinedInput, InputAdornment, Button, Chip} from "@material-ui/core";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import SendSharpIcon from '@material-ui/icons/SendSharp';
import RotateLeftSharpIcon from '@material-ui/icons/RotateLeftSharp';
import ErrorOutlineSharpIcon from '@material-ui/icons/ErrorOutlineSharp';
import axios from "axios";

import pathConfig from "../../config";

const useStyles = makeStyles(theme=>({
    root: {
        margin: theme.spacing(8)
    },
    forms: {
        margin: "0 auto",
        marginTop: theme.spacing(16),
        width: "50%"
    },
    input: {
        marginBottom: theme.spacing(4)
    },
    button: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    }
}));

const passwordReg = /^[0-9a-zA-z][\w]{4,18}[0-9a-zA-z]$/

const AdminPassword = (props)=>{
    const {user} = props;
    const classes = useStyles();
    const [values, setValues] = useState({
        showPassword: false,
        message: "",
        current: "",
        newValue: "",
        confirm: ""
    });

    const handleChange = prop => event => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleReset = ()=>{
        setValues({ ...values, current: "", newValue: "", confirm: ""});
    };

    const handleDeleteIcon = ()=>{
        setValues({...values, message: ""});
    };

    const handleCommit = ()=>{
        if(passwordReg.test(values.current)===false){
            setValues({...values, message: "请输入正确的当前密码"});
            return;
        }
        if(passwordReg.test(values.newValue)===false){
            setValues({...values, message: "请输入正确的新密码"});
            return;
        }
        if(passwordReg.test(values.confirm)===false){
            setValues({...values, message: "请输入正确的确认密码格式"});
            return;
        }
        if(values.newValue !== values.confirm){
            setValues({...values, message: "两次密码输入不一致，请修改"});
            return;
        }
        axios.patch(pathConfig.api.adminPassword(user.id), {
            old: values.current,
            password: values.confirm
        }).then(response=>{
            setValues({...values, message: pathConfig.responseCode[response.data.code]});
        });
    };

    return (
        <Container className={classes.root}>
            <Box component="form" className={classes.forms}>
                <FormControl fullWidth variant="outlined" className={classes.input}>
                    <InputLabel htmlFor="current-password">当前密码</InputLabel>
                    <OutlinedInput
                        id="current-password"
                        type={values.showPassword ? 'text' : 'password'}
                        autoFocus={true}
                        onChange={handleChange("current")}
                        value={values.current}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            onClick={handleClickShowPassword}
                            edge="end"
                            >
                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                        }
                        labelWidth={70}
                    />
                </FormControl>
                <FormControl fullWidth variant="outlined" className={classes.input}>
                    <InputLabel htmlFor="new-password">新密码</InputLabel>
                    <OutlinedInput
                        id="new-password"
                        type={values.showPassword ? 'text' : 'password'}
                        onChange={handleChange("newValue")}
                        value={values.newValue}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            onClick={handleClickShowPassword}
                            edge="end"
                            >
                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                        }
                        labelWidth={70}
                    />
                </FormControl>
                <FormControl fullWidth variant="outlined" className={classes.input}>
                    <InputLabel htmlFor="confirm-new-password">确认新密码</InputLabel>
                    <OutlinedInput
                        id="confirm-new-password"
                        type={values.showPassword ? 'text' : 'password'}
                        onChange={handleChange("confirm")}
                        value={values.confirm}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            onClick={handleClickShowPassword}
                            edge="end"
                            >
                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                        }
                        labelWidth={70}
                    />
                </FormControl>
                {
                    values.message && 
                    <Chip
                    icon={<ErrorOutlineSharpIcon />}
                    onDelete={handleDeleteIcon}
                    label={values.message}
                    color="secondary"
                />
                }
                <Container className="text-center">
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={handleCommit}
                        endIcon={<SendSharpIcon/>}
                    >
                        提交
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        onClick={handleReset}
                        endIcon={<RotateLeftSharpIcon/>}
                    >
                        重置
                    </Button>
                </Container>
            </Box>
        </Container>
    )
}

AdminPassword.propTypes = {
    user: PropTypes.object.isRequired
}

export default AdminPassword;