import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, useLocation } from 'react-router-dom';
import { MainLayout as RouteLayout } from './layouts';
import {
    Welcome,
    Admin,
    AdminPassword,
    AdminHistory,
    Lord,
    Article,
    ArticleView,
    ArticleAdd,
    ArticleEdit,
    NotFound
} from './views';

const Routers = (props)=>{
    const [user, setUser] = useState(props.user);
    const query = new URLSearchParams(useLocation().search);
    return (
        <Switch>
            <Route exact path="/">
                <RouteLayout user={user}>
                    <Welcome/>
                </RouteLayout>
            </Route>
            <Route exact path="/admin/:id">
                <RouteLayout user={user}>
                    <Admin user={user} changeUser={setUser}/>
                </RouteLayout>
            </Route>
            <Route path="/admin/:id/password">
                <RouteLayout user={user}>
                    <AdminPassword user={user}/>
                </RouteLayout>
            </Route>
            <Route path="/admin/:id/history">
                <RouteLayout user={user}>
                    <AdminHistory user={user}/>
                </RouteLayout>
            </Route>
            <Route path="/lords/:id">
                <RouteLayout user={user}>
                    <Lord user={user}/>
                </RouteLayout>
            </Route>
            <Route exact path="/articles">
                <RouteLayout user={user}>
                    {query.get('type') ? <ArticleAdd/> : <Article/>}
                </RouteLayout>
            </Route>
            <Route exact path="/articles/:id">
                <RouteLayout user={user}>
                    {query.get('type') ? <ArticleEdit/> : <ArticleView/>}
                </RouteLayout>
            </Route>
            <Route exact path="*">
                <RouteLayout user={user}>
                    <NotFound/>
                </RouteLayout>
            </Route>
        </Switch>
    )
}

Routers.propTypes = {
    user: PropTypes.object.isRequired
}
export default Routers;