import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import Axios from 'axios';
import config from '../../config';
import { makeStyles, Container, Avatar, Box, TextField, Link, Button, Fab, Tooltip, Chip } from '@material-ui/core';
import AvatarEditor from "react-avatar-editor";
import GitHubIcon from '@material-ui/icons/GitHub';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import EmailIcon from '@material-ui/icons/Email';
import SendSharpIcon from '@material-ui/icons/SendSharp';
import RotateLeftSharpIcon from '@material-ui/icons/RotateLeftSharp';
import DoneIcon from '@material-ui/icons/Done';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import AddIcon from '@material-ui/icons/Add';


const useStyles = makeStyles(theme=>({
    root: {
        margin: theme.spacing(8)
    },
    avatar: {
        width: theme.spacing(18),
        height: theme.spacing(18),
        margin: "0 auto",
        cursor: "pointer"
    },
    forms: {
        margin: "0 auto",
        marginTop: theme.spacing(8),
        width: "50%"
    },
    inputs: {
        marginTop: theme.spacing(4)
    },
    icons: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    }
}));

const Lord = (props)=>{
    const {user} = props;
    const classes = useStyles();
    const [local, setLocal] = useState({
        nick: '',
        motto: ''
    });
    const [values, setValues] = useState(local);

    const icons = {
        github: <GitHubIcon/>,
        twitter: <TwitterIcon/>,
        facebook: <FacebookIcon/>,
        email: <EmailIcon/>
    }
    let editorRef;

    const handleInputChange = (prop)=>(event)=>{
        setLocal({...local, [prop]: event.target.value})
    };

    const handleReset = ()=>{
        setLocal(values);
    };

    const handleChangeAvatar = (event)=>{
        setLocal({...local, avatar: event.target.files[0], changeAvatar: true})
        event.target.value = "";
    };

    const handleAvatarCancel = ()=>{
        setLocal({...local, avatar: values.avatar, changeAvatar: false})
    };

    const handleSocialDelete = (prop)=>()=>{
        setLocal({...local, social: local.social.filter(value=>value.name !== prop)});
    };

    const handleAvatarDone = ()=>{
        const newAvatar = editorRef.getImageScaledToCanvas().toDataURL("image/jpeg", 0.8);
        // let newAvatar = editorRef.getImage().toDataURL("image/jpeg", 1.0);
        Axios.post(config.api.avatar, {
            "resources": newAvatar.replace(/^data:image\/\w+;base64,/, "")
        }).then((response)=>{
            if(response.data.code === 0){
                setLocal({...local, avatar: response.data.result, changeAvatar: false})
            }
        });
    };

    const handleCommit = ()=>{
        Axios.put(config.api.lord(user.id), {
            nick: local.nick,
            avatar: local.avatar,
            motto: local.motto,
            social: local.social
        }).then(response=>{
            if(response.data.code === 0){
                setLocal(response.data.result);
                setValues(response.data.result);
            }
        });
    };

    useEffect(()=>{
        Axios.get(config.api.lord(user.id))
        .then(response=>{
            if(response.data.code === 0){
                setValues(response.data.result);
                setLocal(response.data.result);
            }
        });
    }, [user.id]);

    return (
        <Container className={classes.root}>
            {
                local.changeAvatar ? 
                <Container className="text-center">
                        <AvatarEditor
                        ref={(editor) => editorRef = editor}
                        image={local.avatar}
                        width={250}
                        height={250}
                        border={5}
                        borderRadius={125}
                        color={[255, 255, 255, 0.6]} // RGBA
                        scale={1.2}
                        rotate={0}
                        />  
                        <Container>
                            <Fab color="primary" size="small" aria-label="done" className={classes.icons} onClick={handleAvatarDone}>
                                <DoneIcon />
                            </Fab>
                            <Fab color="secondary" size="small" aria-label="cancel" className={classes.icons} onClick={handleAvatarCancel}>
                                <KeyboardReturnIcon />
                            </Fab>
                        </Container>
                    </Container>
                :
                <Avatar alt={local.nick} src={local.avatar} className={classes.avatar} onClick={()=>{document.getElementsByName("avatar-file")[0].click()}}/>
            }
            <Box component="form" className={classes.forms}>
                <input type="file" className="hidden" name="avatar-file" accept="image/png,image/jpeg" onChange={handleChangeAvatar}/>
                <Box className="text-center">
                    {
                        local.social && local.social.map(value=>(
                            <Chip 
                            key={value.name}
                            className={classes.icons} 
                            label={value.name} 
                            icon={
                                <Link target="_blank" alt={value.alt} title={value.alt} rel="noopener" href={value.url} color="inherit">
                                    {icons[value.className]}
                                </Link>
                            } 
                            onDelete={handleSocialDelete(value.name)}/>
                        ))
                    }

                    <Tooltip className={classes.icons} title="新增社交链接-暂未实现" aria-label="add">
                        <Fab color="secondary" size="small">
                            <AddIcon/>
                        </Fab>
                    </Tooltip>
                </Box>
                <TextField
                    id="nick"
                    label="昵称"
                    className={classes.inputs}
                    placeholder="请输入昵称"
                    helperText="主页展示的个人昵称"
                    fullWidth
                    value={local.nick}
                    margin="normal"
                    variant="outlined"
                    onChange={handleInputChange("nick")}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    />
                <TextField
                    id="motto"
                    label="个人简介"
                    className={classes.inputs}
                    placeholder="请输入个人简介"
                    helperText="主页展示的个人简介"
                    fullWidth
                    value={local.motto}
                    margin="normal"
                    variant="outlined"
                    onChange={handleInputChange("motto")}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    />
                    <Container className="text-center">
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.icons}
                            onClick={handleCommit}
                            endIcon={<SendSharpIcon/>}
                        >
                            提交
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.icons}
                            onClick={handleReset}
                            endIcon={<RotateLeftSharpIcon/>}
                        >
                            重置
                        </Button>
                </Container>
            </Box>
        </Container>
    )
}

Lord.propTypes = {
    user: Proptypes.object.isRequired
}

export default Lord;