import React, { useState, useEffect } from "react";
import axios from 'axios';
import urlConfig from './config';
import { Backdrop, CircularProgress } from "@material-ui/core";
import Routers from "./Routers";
import "moment/locale/zh-cn";

axios.defaults.baseURL = process.env.REACT_APP_API_DOMAIN;

const Auth = ()=>{
    const {token, adminId} = window.localStorage;
    const [user, setUser] = useState();

    if(typeof token === 'undefined' || typeof adminId === 'undefined'){
        window.location = urlConfig.login;
    }

    useEffect(()=>{
        axios.get(urlConfig.api.admin(adminId), {
            headers: {Token :token}
        }).then((response)=>{
            if(typeof response.data === 'object'){
                if(response.data.code === 0){
                    axios.defaults.headers.common["Token"] = token;
                    setUser(Object.assign(response.data.result, {id: adminId, token:token}));
                    return;
                }
            }
            setTimeout(() => {
                window.location = urlConfig.login;
            }, 5000);
        }).catch(()=>{
            setTimeout(() => {
                window.location = urlConfig.login;
            }, 5000);
        })
    }, [token, adminId]);
    
    return (
        typeof user === 'undefined' ?
        <Backdrop open>
            <CircularProgress/>
        </Backdrop> : 
        <Routers user={user}/>
    )
}

export default Auth;